<template>
  <el-dialog
      :title="title"
      :visible.sync="createDialogVisible"
      width="800px"
      :close-on-click-modal="false"
      :append-to-body="true"
      v-drag-dialog
      @close="close">
    <div class="pageContainer">
      <el-form label-position="right"
               :model="formData"
               :rules="newsRules"
               ref="ParkForm"
               label-width="100px"
               element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-row>
              <el-col :span="12">
                  <el-form-item label="动态标题" prop="title">
                      <el-input v-model="formData.title" placeholder="请输入动态名称"></el-input>
                  </el-form-item>
              </el-col>
              <el-col :span="12">
                  <el-form-item label="发布者" prop="publisher">
                      <el-input v-model="formData.publisher" placeholder="请输入发布者"></el-input>
                  </el-form-item>
              </el-col>
          </el-row>
        <el-row>
<!--          <el-col :span="12">-->
<!--            <el-form-item label="园区地址" prop="location">-->
<!--              <el-input v-model="formData.location" placeholder="请输入园区地址"></el-input>-->
<!--&lt;!&ndash;              <file-upload-btn @uploadSuccess="handleUploadSuccess"></file-upload-btn>&ndash;&gt;-->
<!--            </el-form-item>-->
<!--          </el-col>-->
            <el-col :span="12">
                <el-form-item label="园区动态封面" prop="cover">
                    <file-upload-btn v-if="createDialogVisible" :default-value="formData.cover" @uploadSuccess="handleUploadSuccess"></file-upload-btn>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-form-item label="园区动态内容" prop="content">
                <wang-editor v-model="formData.content"
                             :show-status="showStatus"
                             :custom-config="{ height: 400 }"></wang-editor>
            </el-form-item>
        </el-row>
        <el-row>
            <el-form-item label="园区动态内容链接" prop="url">
                <el-input v-model="formData.url"></el-input>
            </el-form-item>
        </el-row>
      </el-form>
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="trySubmit" :loading="addingLoading">确认提交</el-button>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import FileUploadBtn from 'modules/components/fileUploadBtn/index.vue'
import WangEditor from 'modules/components/WangEditor/index.vue'
import { newsRules, resetParkNews, execute } from '../option'

export default {
  name: 'addOrEditPark',
  components: {
    FileUploadBtn, WangEditor
  },
  data() {
    return {
      optType: 'add',
      createDialogVisible: false,
      callback: null,
      formData: resetParkNews(),
      newsRules,
      addingLoading: false,
      showStatus: false
    }
  },
  computed: {
    title() {
      return this.optType === 'add' ? '新增园区资讯' : '编辑园区资讯'
    }
  },
  methods: {
    ...mapActions(['saveParkNews', 'getParkNewsById', 'updateParkNews']),
    show(options) {
      this.optType = options.optType
      this.callback = options.callback
      this.showStatus = true
      if (this.optType === 'edit') {
        this.tryGetParkNewsById(options.detail.id)
        // this.formData = options.detail
      } else {
        this.formData = resetParkNews()
      }
      this.formData.parkId = options.detail.id
      this.createDialogVisible = true
      this.$nextTick(() => {
        this.$refs.ParkForm.clearValidate()
      })
    },
    close() {
      this.showStatus = false
      this.createDialogVisible = false
    },
    showImg(imgUrl, title) {
      execute('showImg', {
        imgUrl,
        title
      })
    },
    handleUploadSuccess(url) {
      this.formData.cover = url
    },

    tryGetParkNewsById(id) {
      this.getParkNewsById(id).then(res => {
        console.log(this.formData)
        this.formData = res || this.formData
      })
    },
    tryUpdateParkNews() {
      this.addingLoading = true
      this.updateParkNews(this.formData).then(() => {
        this.$message.success('操作成功')
        this.callback && this.callback()
        this.close()
      }).finally(() => {
        this.addingLoading = false
      })
    },
    trySubmit() {
      if (this.addingLoading) {
        return
      }
      this.$refs.ParkForm.validate((valid) => {
        if(valid) {
          if (!this.formData.content && !this.formData.url) {
            this.$message.warning('动态内容和动态内容链接不能同时为空！')
            return
          }
          const api = this.optType === 'add' ? this.trySaveParkNews : this.tryUpdateParkNews
          api()
        }
      })
    },
    trySaveParkNews() {
      this.addingLoading = true
      this.saveParkNews(this.formData).then(() => {
        this.$message.success('提交成功')
        this.resetParkNews()
        this.callback && this.callback()
        this.close()
      }).finally(() => {
        this.addingLoading = false
      })
    },
    resetParkNews() {
      this.formData = resetParkNews()
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../../scss/elementVar";

.pageContainer {
  padding: 20px;
}
.tagWrap {
  margin-bottom: 20px;
}
.uploadBtn {
  padding: 30px 0;
  width: 150px;
  height: 100px;
  box-sizing: border-box;
  text-align: center;
  color: $--color-info;
  border: 2px dashed $--color-info;
  opacity: 0.7;
  border-radius: 4px;
  transition: all linear 100ms;
}
.uploadBtn:hover {
  opacity: 1;
}
.uploadBtnIcon {
  font-weight: bold;
  font-size: 30px;
}
.uploadBtnText {
  line-height: 10px;
  font-size: 14px;
}
.imgContainer {
  float: left;
  margin: 0 10px 10px 0;
}
.smallImg{
  width: 40px;
}
</style>
